import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// Components
import CheckoutsList from '../components/Checkouts/CheckoutsList';
import CheckoutsHeader from '../components/Checkouts/CheckoutsHeader';
import CheckoutsModals from '../components/Checkouts/CheckoutsModals';
import CheckoutsPagination from '../components/Checkouts/CheckoutsPagination';
// Actions
import {
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  setDetailedCheckout,
  setEditCheckout,
  patchCheckout,
} from '../redux/actions/checkouts';
// Utils
import { connect } from 'react-redux';

const CheckoutsPage = ({
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  merchantId,
  merchants,
  getState,
  limit,
  merchantData,
  setDetailedCheckout,
  detailedCheckoutState,
  editCheckoutState,
  setEditCheckout,
  patchCheckout,
  ifCreateFetching,
  ifPatchFetching,
  getMerchantData,
  getMerchants,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [editStep, setEditStep] = useState(0);
  const [checkoutCustomerInfo, setCheckoutCustomerInfo] = useState({
    collectName: false,
    collectEmail: false,
  });
  const [checkoutType, setCheckoutType] = useState(null);
  const [isDuplicating, setIsDuplicating] = useState(null);
  const [search, setSearch] = useState(null);
  const [activeMerchantCurrencies, setActiveMerchantCurrencies] = useState([]);
  const { data, page, pages, countItem, fetching } = getState;

  const CHECKOUT_CREATE_METHODS = {
    donation: createCheckoutDonation,
    sale: createCheckoutSale,
    saletoken: createCheckoutSellCurrency,
    cart: createCheckoutCart,
  };

  useEffect(() => {
    setSearch(null);
    if (merchantId) {
      getCheckoutListHandler(1);
      getMerchantData(merchantId);
    }
  }, [merchantId]);

  useEffect(() => {
    if (merchantId && merchants && merchants.length) {
      setActiveMerchantCurrencies(merchants.find(merchant => merchantId === merchant._id).currencies);
    }
  }, [merchantId, merchants]);

  const getCheckoutListHandler = (pageToGet, search = null) => {
    getCheckoutList({
      merchantId: merchantId,
      params: { page: pageToGet, search: search ? search.trim().split(/ +/).join(' ') : null, limit, order: 'DESC' },
    });
  };

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  const createCheckoutHandler = body => {
    const {
      description,
      organizationName,
      productName,
      cartName,
      currencies,
      image,
      linkImage,
      logoImage,
      linkLogoImage,
      backgroundColor,
      accentColor,
      fiatCurrency,
      price,
      expireTime,
      min,
      max,
      fixed,
      tradedCurrency,
      tradedWallet,
      linkSite,
    } = body;
    const formData = new FormData();
    formData.append('description', description);
    formData.append('expireTime', expireTime);
    formData.append('collectName', checkoutCustomerInfo.collectName);
    formData.append('collectEmail', checkoutCustomerInfo.collectEmail);

    // TO DO
    formData.append('currencies', JSON.stringify(currencies));

    if (image) {
      formData.append('image', image, image.name);
    }

    if (linkImage) {
      formData.append('linkImage', linkImage);
    }

    if (logoImage) {
      formData.append('logoImage', logoImage, logoImage.name);
    }

    if (linkLogoImage) {
      formData.append('linkLogoImage', linkLogoImage);
    }

    if (backgroundColor) {
      formData.append('backgroundColor', backgroundColor);
    }

    if (accentColor) {
      formData.append('accentColor', accentColor);
    }
    if (linkSite) {
      formData.append('linkSite', linkSite);
    }

    if (checkoutType === 'donation') {
      formData.append('organizationName', organizationName);
    }

    if (checkoutType === 'sale') {
      formData.append('productName', productName);
      formData.append('fiatCurrency', fiatCurrency);
      formData.append('price', price);
    }

    if (checkoutType === 'saletoken') {
      formData.append('productName', productName);
      if (fixed === 'true') {
        formData.append('fiatCurrency', fiatCurrency);
        formData.append('price', price);
      }
      formData.append('min', min);
      formData.append('max', max);
      formData.append('fixed', fixed);
      formData.append('tradedCurrency', tradedCurrency);
      formData.append('tradedWallet', tradedWallet);
    }

    if (checkoutType === 'cart') {
      formData.append('cartName', cartName);
      formData.append('fiatCurrency', fiatCurrency);
    }

    checkoutType &&
      CHECKOUT_CREATE_METHODS[checkoutType]({
        merchantId: merchantId,
        body: formData,
      });
  };
  const editCheckoutHandler = body => {
    const {
      description,
      linkSite,
      organizationName,
      productName,
      cartName,
      currencies,
      image,
      linkImage,
      logoImage,
      linkLogoImage,
      backgroundColor,
      accentColor,
      fiatCurrency,
      price,
      expireTime,
      min,
      max,
      fixed,
      tradedCurrency,
      tradedWallet,
    } = body;
    const formData = new FormData();
    formData.append('description', description);
    formData.append('expireTime', expireTime);
    formData.append('collectName', checkoutCustomerInfo.collectName);
    formData.append('collectEmail', checkoutCustomerInfo.collectEmail);

    // TO DO
    formData.append('currencies', JSON.stringify(currencies));

    if (linkImage) {
      formData.append('linkImage', linkImage);
    }

    if (linkLogoImage) {
      formData.append('linkLogoImage', linkLogoImage);
    }

    if (backgroundColor) {
      formData.append('backgroundColor', backgroundColor);
    }

    if (accentColor) {
      formData.append('accentColor', accentColor);
    }
    
    if (linkSite) {
      formData.append('linkSite', linkSite);
    }

    if (editCheckoutState.type.toLowerCase() === 'donation') {
      formData.append('organizationName', organizationName);
    }

    if (editCheckoutState.type.toLowerCase() === 'sale') {
      formData.append('productName', productName);
      formData.append('fiatCurrency', fiatCurrency);
      formData.append('price', price);
    }

    if (editCheckoutState.type.toLowerCase() === 'saletoken') {
      formData.append('productName', productName);
      // TODO: do not send fiatCurrency and price fields if checkout type is float
      if (fixed === 'true') {
        formData.append('fiatCurrency', fiatCurrency);
        formData.append('price', price);
      }
      formData.append('min', min);
      formData.append('max', max);
      formData.append('fixed', fixed);
      formData.append('tradedCurrency', tradedCurrency);
      formData.append('tradedWallet', tradedWallet);
    }

    if (editCheckoutState.type.toLowerCase() === 'cart') {
      formData.append('cartName', cartName);
      formData.append('fiatCurrency', fiatCurrency);
    }

    if (image === 'delete') {
      formData.append('deleteImage', true);
    } else {
      image && formData.append('image', image, image.name);
    }

    if (logoImage === 'delete') {
      formData.append('deleteLogoImage', true);
    } else {
      logoImage && formData.append('logoImage', logoImage, logoImage.name);
    }

    patchCheckout({
      body: formData,
      merchantId: merchantId,
      type: editCheckoutState.type.charAt(0).toLowerCase() + editCheckoutState.type.slice(1),
      checkoutId: editCheckoutState._id,
    }).then(response => {
      modalCloseHandler();
    });
  };

  console.log(checkoutType)

  const modalCloseHandler = () => {
    setIsVisible(false);
    setCheckoutType(null);
    setDetailedCheckout(null);
    setEditCheckout(null);
    setIsDuplicating(null);
    setCheckoutCustomerInfo({
      collectName: false,
      collectEmail: false,
    });
    setStep(0);
    setEditStep(0);
  };

  const nextStep = () => setStep(step + 1);

  const prevStep = () => {
    setStep(step - 1);
  };

  const openDetailedHandler = checkout => {
    setIsVisible(true);
    setDetailedCheckout(checkout);
  };

  const openEditHandler = checkout => {
    setIsVisible(true);
    setEditCheckout(checkout);
  };

  const openDuplicateHandler = checkout => {
    setIsDuplicating(true);
    setIsVisible(true);
    setCheckoutType(checkout?.type.toLowerCase());
    setEditCheckout(checkout);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="block-for-component">
        <div className="component">
          <CheckoutsModals
            visible={isVisible}
            close={modalCloseHandler}
            isDuplicating={isDuplicating}
            checkoutCustomerInfo={checkoutCustomerInfo}
            setCheckoutCustomerInfo={setCheckoutCustomerInfo}
            setCheckoutType={setCheckoutType}
            checkoutType={checkoutType}
            createCheckoutHandler={createCheckoutHandler}
            editCheckoutHandler={editCheckoutHandler}
            nextStep={nextStep}
            prevStep={prevStep}
            step={step}
            editStep={editStep}
            setEditStep={setEditStep}
            detailedCheckoutState={detailedCheckoutState}
            editCheckoutState={editCheckoutState}
            ifCreateFetching={ifCreateFetching}
            ifPatchFetching={ifPatchFetching}
            activeMerchantCurrencies={activeMerchantCurrencies}
            merchantId={merchantId}
            merchants={merchants}
          />
          <CheckoutsHeader
            fetching={fetching}
            setIsVisible={setIsVisible}
            data={data}
            search={search}
            setSearch={setSearch}
            getCheckoutListHandler={getCheckoutListHandler}
          />
          <CheckoutsList
            data={data}
            page={page}
            fetching={fetching}
            setIsVisible={setIsVisible}
            merchantId={merchantId}
            deleteCheckout={deleteCheckout}
            openDetailedHandler={openDetailedHandler}
            openEditHandler={openEditHandler}
            openDuplicateHandler={openDuplicateHandler}
          />
          {pages > 1 && (
            <CheckoutsPagination
              page={page}
              pages={pages}
              search={search}
              countItem={countItem}
              limit={limit}
              getCheckoutListHandler={getCheckoutListHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  merchantData: state.merchantData.data,
  merchants: state.merchants.data,
  getState: state.checkouts.get,
  limit: state.checkouts.limit,
  detailedCheckoutState: state.checkouts.detailedCheckout,
  editCheckoutState: state.checkouts.editCheckout,
  ifCreateFetching: state.checkouts.create.fetching,
  ifPatchFetching: state.checkouts.patch.fetching,
});

const mapDispatchToProps = {
  getCheckoutList,
  createCheckoutDonation,
  createCheckoutSale,
  createCheckoutSellCurrency,
  createCheckoutCart,
  deleteCheckout,
  setDetailedCheckout,
  setEditCheckout,
  patchCheckout,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutsPage);
