import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TokenIcon from '../TokenIcon';
import { getMerchantData } from '../../redux/actions/merchantData';
import tickCircleIcon from '../../img/default-svg/tickCircle.svg';
import { ReactComponent as DropdownIcon } from '../../img/default-svg/chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../img/default-svg/close-black.svg';
import { identity } from '../../utils/getIdentity';


import { useTranslation } from 'react-i18next';
import './style.scss';
import { Tooltip } from 'antd';

const removeKey = (key, { [key]: removed, ...rest }) => rest;

const CurrencyDropdown = ({
  currencies,
  merchantCurrencies,
  setFunc,
  multiple = false,
  allCurrencies = true,
  excludeCurrencies = [],
  defaultValues,
  noTokens,
  currenciesFetching,
  merchantId,
  getMerchantData,
  create = false,
  individualTokens = false,
  activeMerchantCurrencies = [],
  isFiltersAvailable = false,
  isOpenedDropdownCurrency,
  setIsOpenedDropdownCurrency,
  visible, setVisible
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const { t } = useTranslation();

  const getTokensName = nodeType => {
    switch (nodeType) {
      case 'eth':
        return '(USDT / USDC / TrueUSD)';
      case 'bsc':
        return '(BUSD / USDT / BTC / USDC / TrueUSD / ADA / DOGE / SHIB / EOS)';
      case 'trx':
        return '(USDT / TrueUSD / USDC)';
      case 'matic':
        return '(USDT / USDC / TrueUSD)';
      case 'optimism':
        return '(ETH / USDT / USDC / TrueUSD)';
      case 'arbitrum':
        return '(ETH / USDT / TrueUSD / USDC)';
      case 'moonbeam':
        return '(USDT / USDC)';
      case 'solana':
        return '(USDC)';
      case 'avax':
        return '(USDT)';
      case 'ftm':
        return '(USDT)';

      default:
        return '';
    }
  };

  const reducedCurrencies = currencies?.reduce((accumulator, currency) => {
    if (
      (noTokens && currency?.currencyType === 'token') ||
      (!allCurrencies && !merchantCurrencies.find(merchantCurrency => merchantCurrency === currency?._id)) ||
      excludeCurrencies.find(excludedCurrency => excludedCurrency === currency?._id)
    ) {
      return accumulator;
    }

    if (
      currency?.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      currency?.name.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      accumulator.push(
        <div className={`grid__item ${selectedCurrency[currency?._id] && 'grid__item--selected'}`} onClick={() => handleSelect(currency)}>
          {(selectedCurrency[currency?._id]) && (
            <img src={tickCircleIcon} className="grid__tick" alt="selected" />
          )}
          <TokenIcon tokenName={currency?.name} className="grid__logo" />
          <div className="grid__title">{currency?.title}
            {noTokens && identity === 'nfg' && <Tooltip title={getTokensName(currency?.nodeType)} placement='top'><span className="currencies-dropdown__tokens-names"> {getTokensName(currency?.nodeType)}</span></Tooltip>}
          </div>
        </div>
      );
    }

    return accumulator;
  }, []);

  useEffect(() => {
    if (merchantId && !merchantCurrencies) {
      getMerchantData(merchantId);
    }
  }, [merchantId, merchantCurrencies]);


  useEffect(() => {
    if (defaultValues && defaultValues.length && currencies.length && !currenciesFetching) {
      const result = defaultValues.reduce((obj, item) => {
        obj[item] = currencies.find(currency => currency._id === item);
        return obj;
      }, {});

      setSelectedCurrency(result);
    }
  }, [defaultValues, currencies]);

  const handleSelectCurrencies = () => {
    const allAvailableCurrencies = currencies.filter(({_id}) => activeMerchantCurrencies.includes(_id))
    const values = allAvailableCurrencies.reduce((a, v) => ({...a, [v._id]: v}), {})
    setFunc(activeMerchantCurrencies)
    setSelectedCurrency(values)
  }

  const handleRemoveCurrencies = () => {
    setSelectedCurrency({})
    setFunc([])
  }

  const handleSelect = currency => {
    if (multiple) {
      let value;
      let isRemoving;

      if (!selectedCurrency[currency._id]) {
        isRemoving = false;
      } else {
        isRemoving = true;
      }

      if (currency.currencyType === 'currency' || individualTokens) {
        if (isRemoving) {
          if (!individualTokens) {
            value = Object.values(selectedCurrency).reduce((acc, item) => {
              return item.nodeType !== currency.nodeType ? { ...acc, [item._id]: item } : acc;
            }, {});
          } else {
            value = removeKey(currency._id, selectedCurrency);
          }
        } else {
          value = { ...selectedCurrency, [currency._id]: currency };
        }
      } else if (currency.currencyType === 'token' && !individualTokens) {
        if (isRemoving) {
          value = removeKey(currency._id, selectedCurrency);
        } else {
          const currencyToAdd = currencies.find(
            item => currency.nodeType === item.nodeType && item.currencyType === 'currency'
          );
          value = { ...selectedCurrency, [currency._id]: currency, [currencyToAdd._id]: currencyToAdd };
        }
      }
      setSelectedCurrency(value);
      setFunc(Object.keys(value));
    } else {
      setSelectedCurrency({ [currency._id]: currency });
      setFunc(currency._id);
    }
  };

  return (
    <div>
      {!create ? (
        <div className="currencies-dropdown">
          <DropdownIcon
            className={`currencies-dropdown__arrow ${isOpenedDropdownCurrency ? 'currencies-dropdown__arrow_opened' : ''}`}
            alt="Dropdows"
            onClick={() => setIsOpenedDropdownCurrency(!isOpenedDropdownCurrency)}
            // onMouseEnter={() => setVisible(true)}
            // onMouseLeave={() => setVisible(false)}
          />
          <div className="currencies-dropdown__selected" onClick={() =>{setIsOpenedDropdownCurrency(!isOpenedDropdownCurrency)}}

          >
            {Object.values(selectedCurrency).length
              ? Object.values(selectedCurrency).map(
                currency =>
                  currency && (
                    <div key={currency.title}  className="currencies-dropdown__selected-item">
                      <TokenIcon tokenName={currency.name} className="currencies-dropdown__logo" />
                      {currency.title}
                    </div>
                  )
              )
              : t('wallets.chooseCurrency')}
          </div>
          {isOpenedDropdownCurrency && (
            <div className="currencies-dropdown__list"
                 onMouseEnter={() => setVisible(true)}
                 onMouseLeave={() => setVisible(false)}
            >
              <div className="currencies-dropdown__search">
                <input
                  type="text"
                  placeholder={t('search')}
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </div>

              {isFiltersAvailable ?
                <div className="currencies-dropdown__filters">
                  <p className={`currencies-dropdown__filters-select currencies-dropdown__filters-select-${identity}`} onClick={() => handleSelectCurrencies()}>{t('selectAll')}</p>
                  <p className="currencies-dropdown__filters-clear"onClick={() => handleRemoveCurrencies()}>{t('clearAll')} <CloseIcon/></p>
                </div>
                : null}

              {(() => {
                const reducedCurrencies = currencies?.reduce((accumulator, currency) => {
                  if (
                    (noTokens && currency?.currencyType === 'token') ||
                    (!allCurrencies && !merchantCurrencies.find(merchantCurrency => merchantCurrency === currency?._id)) ||
                    excludeCurrencies.find(excludedCurrency => excludedCurrency === currency?._id)
                  ) {
                    return accumulator;
                  }

                  if (
                    currency?.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                    currency?.name.toLowerCase().includes(searchValue.toLowerCase())
                  ) {
                    accumulator.push(
                      <div className={`currencies-dropdown__item`} onClick={() => handleSelect(currency)}>
                        {(selectedCurrency[currency?._id]) && (
                          <img src={tickCircleIcon} className="currencies-dropdown__tick" alt="selected" />
                        )}
                        <TokenIcon tokenName={currency?.name} className="currencies-dropdown__logo" />
                        <div className="currencies-dropdown__title">{currency?.title}
                          {noTokens && identity === 'nfg' && <Tooltip title={getTokensName(currency?.nodeType)} placement='top'><span className="currencies-dropdown__tokens-names"> {getTokensName(currency?.nodeType)}</span></Tooltip>}
                        </div>
                      </div>
                    );
                  }

                  return accumulator;
                }, []);

                return reducedCurrencies?.length ? (
                  reducedCurrencies
                ) : (
                  <div className="currencies-dropdown__empty">{t('nodata')}</div>
                );
              })()}
            </div>
          )}
        </div>
      ) : <div>{reducedCurrencies?.length ? (
        <div>
          <div className="select">
            <div className="select__all"  onClick={() => handleSelectCurrencies()}>{t('selectAll')}</div>
            <div className="select__clear" onClick={() => handleRemoveCurrencies()}>{t('clearAll')}</div>
          </div>
          <div className="grid">{reducedCurrencies}</div>
        </div>
      ) : null}</div>}
    </div>
  );
};

const mapStateToProps = state => ({
  merchantId: state.transactions.merchantId,
  currencies: state.currencies.data,
  merchantCurrencies: state.merchantData.data.currencies,
  currenciesFetching: state.currencies.fetching,
});

const mapDispatchToProps = dispatch => ({
  getMerchantData: getMerchantData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDropdown);
