import React, { useState, useEffect } from 'react';
import Button from '../../Button';
import { Row, Col, Form, Input, Spin, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { notificationsErrorShow } from '../../../redux/actions/notifications';

import { identity } from '../../../utils/getIdentity';
import ResendPhone2FA from '../../../ResendPhone2FA';
import ResendEmail2FA from '../../ResendEmail2FA';

import './styles.scss';
import '../../../layout/Modal.scss';

const Passphrase = ({
  isFetching,
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  notificationsErrorShow,
  merchantId,
  selectedWallet,
  setSelectedWallet,
  openNotification,
  getPassphrase,
  setOpenSettingsModal,
  walletKey,
  appTwoFa,
  emailTwoFa,
  verifyCode,
  setWalletKey,
  setAppTwoFa,
  setEmailTwoFa,
  setVerifyCode,
}) => {
  const [walletIds, setWalletIds] = useState('');
  const { t } = useTranslation();

  const [twoFaForm] = Form.useForm();

  useEffect(() => {
    if (!isAppTwoFa && !isEmailTwoFa && !isPhoneTwoFa) {
      twoFaForm.resetFields();
      setWalletKey('');
      setWalletIds('');
      setAppTwoFa('');
      setEmailTwoFa('');
      setVerifyCode('');
      setSelectedWallet(null);
    }
  }, [isAppTwoFa, isEmailTwoFa, isPhoneTwoFa]);

  const closeModal = () => {
    setSelectedWallet(null);
    setOpenSettingsModal(false);
  };

  const handleSubmitTwoFa = async () => {
    await twoFaForm.validateFields();
    return getPassphrase(merchantId, selectedWallet?._id, appTwoFa, emailTwoFa, verifyCode).then(response => {
      if (response && response.status === 'success') {
        setWalletIds(response?.data?.walletId);
        setWalletKey(response?.data?.passphrase);
      }

      if (response && response.status === 'fail') {
        if (isAppTwoFa && !appTwoFa) {
          notificationsErrorShow({ message: t('validation.twoFaRequired') });
          return;
        }

        if (isEmailTwoFa && !emailTwoFa) {
          notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
          return;
        }

        if (isPhoneTwoFa && !verifyCode) {
          notificationsErrorShow({ message: t('validation.phoneTwoFaRequired') });
          return;
        }
      }
    });
  };

  return (
    <div className="key">
      <div className="key__container">
        {isFetching && !isEmailTwoFa && (
          <div className="modal__spinner">
            <Spin />
          </div>
        )}
        <Form
          form={twoFaForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-form"
        >
          {!isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
            <Row className="modal__buttons-wrapper">
              <Col span="24">
                <Button
                  type="primary"
                  className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
                  onClick={() => getPassphrase(merchantId, selectedWallet._id, '', '', '', 'corporate')}
                >
                  Send
                </Button>
              </Col>
            </Row>
          )}
          {isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
            <>
              <Form.Item
                label={t('auth.сodeFromSms')}
                name="verifyCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.сodeFromSms')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setVerifyCode(e.target.value)}
                />
              </Form.Item>
              <ResendPhone2FA />
            </>
          )}
          {isAppTwoFa && !walletKey && (
            <Form.Item
              label={t('auth.keyFromGA')}
              name="appTwoFaCode"
              className="modal-form__label"
              rules={[{ required: true, message: t('validation.twoFaRequired') }]}
            >
              <Input
                placeholder={t('auth.keyFromGA')}
                className="modal-form__input"
                maxLength="6"
                onChange={e => setAppTwoFa(e.target.value)}
              />
            </Form.Item>
          )}

          {isEmailTwoFa && !isAppTwoFa && (
            <>
              <Form.Item
                label={t('auth.keyFromEmail')}
                name="emailTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromEmail')}
                  className="modal-form__input"
                  maxLength="6"
                  value={emailTwoFa}
                  onChange={e => setEmailTwoFa(e.target.value)}
                />
              </Form.Item>
              <ResendEmail2FA />
            </>
          )}
        </Form>

        {walletKey && (
          <React.Fragment>
            <div className="wrapper">
              <div>{t('walletId')}: </div>
              <div className="text__wallet">
                {walletIds}
              </div>
            </div>
            <div className="wrapper">
              <div>{t('passphrase')}: </div>
              <div className="modal__text text_copy" onClick={openNotification}>
                <Tooltip title={t('clickToCopy')} placement="top">
                  <CopyToClipboard text={walletKey}>
                    <span>{walletKey}</span>
                  </CopyToClipboard>
                </Tooltip>
              </div>
            </div>

            <div className="modal__warning">
              <span className="modal__warning_red">{t('important')}</span>
              {t('merchants.clickToCopyPassphrase')}
            </div>
          </React.Fragment>
        )}

        {(isPhoneTwoFa || isEmailTwoFa || isAppTwoFa) && (
          <Row className="modal__buttons-wrapper">
            <Col span="24">
              <Button
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
                loading={isFetching && isEmailTwoFa}
                onClick={walletKey ? closeModal : handleSubmitTwoFa}
              >
                {walletKey ? t('done') : t('confirm')}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isPhoneTwoFa: state.merchantWallets.isPhoneTwoFa,
  isFetching: state.merchantWallets.modalFetching,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Passphrase);