import React from 'react';
import Wallet from '../Wallet';
import emptyCpayImage from '../../../../img/cpay-svg/empty.svg';
import emptyNFGImage from '../../../../img/nfgpay-svg/empty-nfg.svg';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';
import { identity } from '../../../../utils/getIdentity';

const emptyImage = {
  cpay: emptyCpayImage,
  nfg: emptyNFGImage
}


const WalletsList = ({ withdrawWallets, walletsFetching, selectedWallet, setSelectedWallet, setFrom }) => {
  return (
    <div className="fee-wallets-list">
      {!walletsFetching ? (
        <div className="fee-wallets-list__list">
          {withdrawWallets.length ? (
            withdrawWallets.map((wallet, index) => {
              return (
                <Wallet
                  key={index}
                  wallet={wallet}
                  selectedWallet={selectedWallet}
                  setSelectedWallet={setSelectedWallet}
                  setFrom={setFrom}
                />
              );
            })
          ) : (
            <div className="fee-wallets-list__empty">
              <span className="transactions-list__empty-title">All available wallets will show up here.</span>
              <img src={emptyImage[identity]} className="transactions-list__empty-image" alt="empty" />
            </div>
          )}
          {/* {selectedWallet && (
            <button
              type="button"
              className={`fee-wallets-list__select fee-wallets-list__select-${identity}`}
              disabled={!selectedWallet}
              onClick={() => {
                setFrom(selectedWallet.address);
              }}
            >
              Send
            </button>
          )} */}
        </div>
      ) : (
        <div className="fee-wallets-list__loader">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default WalletsList;
