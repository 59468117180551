import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, Form, Input, Spin, Tooltip } from 'antd';
import Button from '../Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { notificationsErrorShow } from '../../redux/actions/notifications';

import '../../layout/Modal.scss';
import './PrivateKeyModal.scss';
import { identity } from '../../utils/getIdentity';
import ResendPhone2FA from '../../ResendPhone2FA';
import ResendEmail2FA from '../ResendEmail2FA';

const PrivateKeyModal = ({
  isAppTwoFa,
  isEmailTwoFa,
  isPhoneTwoFa,
  merchantId,
  walletId,
  setWalletId,
  isFetching,
  isKeyModalOpen,
  closeModal,
  openNotification,
  getWalletPrivateKey,
  notificationsErrorShow,
  showWalletBy,
  getWalletMnemonicPhrase,
  getPassphrase
}) => {

  const [walletKey, setWalletKey] = useState('');
  const [passphraseWalletId, setPassphraseWalletId] = useState('');
  const [appTwoFa, setAppTwoFa] = useState('');
  const [emailTwoFa, setEmailTwoFa] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const { t } = useTranslation();

  const [twoFaForm] = Form.useForm();

  const PRIVATE_KEY_MODAL_DATA = {
    mnemonicPhrase: {
      title: 'merchants.yourMnemonicPhrase',
      handleSubmit: getWalletMnemonicPhrase,
      name: 'mnemonicPhrase',
    },

    privateKey: {
      title: 'merchants.yourPrivateKey',
      handleSubmit: getWalletPrivateKey,
      name: 'privateKey',
    },

    passphrase: {
      title: 'merchants.yourPassphrase',
      handleSubmit: getPassphrase,
      name: 'passphrase'
    }
  };

  useEffect(() => {
    if (!isKeyModalOpen && !isAppTwoFa && !isEmailTwoFa && !isPhoneTwoFa) {
      twoFaForm.resetFields();
      setWalletKey('');
      setPassphraseWalletId('');
      setAppTwoFa('');
      setEmailTwoFa('');
      setVerifyCode('');
      setWalletId('');
    }
  }, [isKeyModalOpen, isAppTwoFa, isEmailTwoFa, isPhoneTwoFa]);

  const handleSubmitTwoFa = async () => {
    await twoFaForm.validateFields();
    return PRIVATE_KEY_MODAL_DATA[showWalletBy]
      ?.handleSubmit(merchantId, walletId, appTwoFa, emailTwoFa, verifyCode)
      .then(response => {
        if (response && response.status === 'success') {
          if (PRIVATE_KEY_MODAL_DATA[showWalletBy]?.name === 'passphrase') {
            setPassphraseWalletId(response.data?.walletId);
            setWalletKey(response.data?.passphrase);
          } else {
            setWalletKey(response.data);
          }
        }

        if (response && response.status === 'fail') {
          if (isAppTwoFa && !appTwoFa) {
            notificationsErrorShow({ message: t('validation.twoFaRequired') });
            return;
          }

          if (isEmailTwoFa && !emailTwoFa) {
            notificationsErrorShow({ message: t('validation.emailTwoFaRequired') });
            return;
          }

          if (isPhoneTwoFa && !verifyCode) {
            notificationsErrorShow({ message: t('validation.phoneTwoFaRequired') });
            return;
          }
        }
      });
  };

  return (
    <React.Fragment>
      <Modal
        title={t(PRIVATE_KEY_MODAL_DATA[showWalletBy]?.title)}
        visible={isKeyModalOpen && (isAppTwoFa || isEmailTwoFa || isPhoneTwoFa)}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
        className="modal modal__private-key"
      >
        <div className="security-pass">
          {isFetching && !isEmailTwoFa && (
            <div className="modal__spinner">
              <Spin />
            </div>
          )}
          <Form
            form={twoFaForm}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="basic"
            initialValues={{ remember: true }}
            className="modal-form"
          >
            {isPhoneTwoFa && !isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.сodeFromSms')}
                  name="verifyCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.phoneTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.сodeFromSms')}
                    className="modal-form__input"
                    maxLength="6"
                    onChange={e => setVerifyCode(e.target.value)}
                  />
                </Form.Item>
                <ResendPhone2FA />
              </>
            )}
            {isAppTwoFa && !walletKey && (
              <Form.Item
                label={t('auth.keyFromGA')}
                name="appTwoFaCode"
                className="modal-form__label"
                rules={[{ required: true, message: t('validation.twoFaRequired') }]}
              >
                <Input
                  placeholder={t('auth.keyFromGA')}
                  className="modal-form__input"
                  maxLength="6"
                  onChange={e => setAppTwoFa(e.target.value)}
                />
              </Form.Item>
            )}

            {isEmailTwoFa && !isAppTwoFa && (
              <>
                <Form.Item
                  label={t('auth.keyFromEmail')}
                  name="emailTwoFaCode"
                  className="modal-form__label"
                  rules={[{ required: true, message: t('validation.emailTwoFaRequired') }]}
                >
                  <Input
                    placeholder={t('auth.keyFromEmail')}
                    className="modal-form__input"
                    maxLength="6"
                    value={emailTwoFa}
                    onChange={e => setEmailTwoFa(e.target.value)}
                  />
                </Form.Item>
                <ResendEmail2FA />
              </>
            )}
          </Form>

          {walletKey && (
            <React.Fragment>
              {PRIVATE_KEY_MODAL_DATA[showWalletBy]?.name === 'passphrase' && (
                <div className="wrapper">
                  <div>{t('walletId')}: </div>
                  <div className="text__wallet">
                    {passphraseWalletId}
                  </div>
                </div>
              )}
              {PRIVATE_KEY_MODAL_DATA[showWalletBy]?.name === 'passphrase' && (
                <div className="wrapper">
                  <div>{t('passphrase')}: </div>
                  <div className="modal__text" onClick={openNotification}>
                    <Tooltip title={t('clickToCopy')} placement="top">
                      <CopyToClipboard text={walletKey}>
                        <span>{walletKey}</span>
                      </CopyToClipboard>
                    </Tooltip>
                  </div>
                </div>
                )}

              {PRIVATE_KEY_MODAL_DATA[showWalletBy]?.name !== 'passphrase' && (
                <div className="modal__text" onClick={openNotification}>
                  <Tooltip title={t('clickToCopy')} placement="top">
                    <CopyToClipboard text={walletKey}>
                      <span>{walletKey}</span>
                    </CopyToClipboard>
                  </Tooltip>
                </div>
              )}

              <div className="modal__warning">
                <span className="modal__warning_red">{t('important')}</span>
                {showWalletBy === PRIVATE_KEY_MODAL_DATA?.privateKey.name
                  ? t('merchants.clickToCopyPrivateKey')
                  : showWalletBy === PRIVATE_KEY_MODAL_DATA?.mnemonicPhrase.name
                  ? t('merchants.clickToCopyMnemonic')
                  : t('merchants.clickToCopyPassphrase')}
              </div>
            </React.Fragment>
          )}

          <Row className="modal__buttons-wrapper">
            <Col span="24">
              <Button
                type="primary"
                className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
                loading={isFetching && isEmailTwoFa}
                onClick={walletKey ? closeModal : handleSubmitTwoFa}
              >
                {walletKey ? t('done') : t('send')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAppTwoFa: state.merchantWallets.isAppTwoFa,
  isEmailTwoFa: state.merchantWallets.isEmailTwoFa,
  isPhoneTwoFa: state.merchantWallets.isPhoneTwoFa,
  isFetching: state.merchantWallets.modalFetching,
});

const mapDispatchToProps = dispatch => ({
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateKeyModal);
