import React, { useEffect, useState, useRef } from 'react';
import { Modal, Select, Radio, Input, Spin, notification } from "antd";
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getMerchantWallets } from '../../../redux/actions/merchantWallets';
import { truncate } from '../../../utils/truncateFunction';
import { CustomSelectOption } from './CustomSelectOption';
import repeatIcon from '../../../img/default-svg/repeat.svg';
import dashedLine from '../../../img/default-svg/dashed-line.svg';
import uploadFile from '../../../img/cpay-svg/upload.svg';
import { identity } from '../../../utils/getIdentity';

import { cryptoApi } from '../../../service/cryptopay-api';
import {
  getCurrenciesRate,
  makeWithdraw,
  withdrawResetTwoFa,
  withdrawalEstimateMaxReset,
  uploadNewAutosign,
  checkAutosignStatus,
} from '../../../redux/actions/withdraw';
import WithdrawTwoFaConfirm from '../../Withdraw/WithdrawForm/WithdrawTwoFaConfirm';
import debounce from 'lodash/debounce';

import { Timer } from '../../Timer/Timer';
import { addMinutes } from '../../../utils/addMinutes';

import './styles.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { tokenTypeEnd } from '../../../utils/tokenNodeTypes';
import UploadAutosignWrapper from '../../Withdraw/UploadAutosignWrapper';

const WithdrawModal = ({
  openWithdraw,
  handleCloseWithdraw,
  merchantId,
  networkFilter,
  merchantWallets,
  getMerchantWallets,
  currencyId,
  merchantBalances,
  makeWithdraw,
  withdrawResetTwoFa,
  withdrawalEstimateMaxReset,
  fetching,
  currencies,
  autosignStep,
  uploadNewAutosign,
  checkAutosignStatus,
  autosignStatus,
}) => {
  const { t } = useTranslation();

  const validUntil = addMinutes(1);

  const [selectedCurrencyId, setSelectedCurrencyId] = useState(currencyId || undefined);
  const [selectedCurrency, setSelectedCurrency] = useState(undefined);
  const [selectedWallet, setSelectedWallet] = useState(undefined);
  const [sentToWallet, setSentToWallet] = useState('');
  const [selectedSentToWallet, setSelectedSentToWallet] = useState(undefined);
  const [sendToLabelState, setSendToLabelState] = useState('externalWallet');
  const [sentAmountBalance, setSentAmountBalance] = useState('');
  const [comment, setComment] = useState('');
  const [getAmountUSD, setGetAmountUSD] = useState('0.00');
  const [withdrawalInfo, setWithdrawalInfo] = useState();
  const [showToRequired, setShowToRequired] = useState(false);
  const [showTwoFaModal, setShowTwoFaModal] = useState(false);
  const [fees, setFees] = useState(null);
  const [disableResend, setDisableResend] = useState(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const [showWarningMaxMsg, setShowWarningMaxMsg] = useState(false);
  const [warningMaxMsg, setWarningMaxMsg] = useState('');
  const [showWarningRecipient, setShowWarningRecipient] = useState(false);
  const [warningRecipient, setWarningRecipient] = useState('');
  const [showWarningAddress, setShowWarningAddress] = useState(false);
  const [warningAddress, setWarningAddress] = useState('');
  const [decimalsLimit, setDecimalsLimit] = useState(0);
  const [showDecimalsWarning, setShowDecimalsWarning] = useState(false);
  const [isSelectedWallet, setIsSelectedWallet] = useState(undefined);
  const [destinationWallet, setDestinationWallet] = useState(undefined);
  const [fetchingMaxAmount, setFetchingMaxAmount] = useState(false);
  const [priority, setPriority] = useState('low');
  const [loadingFees, setLoadingFees] = useState(false);
  const [isOpenAutosignModal, setIsOpenAutosignModal] = useState(false);
  const [isShowAutosignModal, setIsShowAutosignModal] = useState(true);
  const [password, setPassword] = useState('');
  const [sign, setSign] = useState('');
  const ratesFetchTimer = useRef();

  const isEstimatePriority =
    (isSelectedWallet?.nodeType === 'eth' && networkFilter === 'mainnet') || isSelectedWallet?.nodeType === 'btc';

  const wallets = selectedCurrency && merchantWallets.filter(w => w.currencyId === selectedCurrencyId);

  const walletsWithTokens = selectedCurrency && merchantWallets?.filter(w => w.tokens.length > 0);

  useEffect(() => () => ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current), []);

  useEffect(() => {
    if (sentToWallet || selectedSentToWallet) {
      const walletToSend = sendToLabelState === 'externalWallet' ? sentToWallet : selectedSentToWallet;
      setDestinationWallet(walletToSend);
    }
  }, [sendToLabelState, sentToWallet, selectedSentToWallet]);

  const walletsWithTokensOrder =
    selectedCurrency &&
    walletsWithTokens.map(w => {
      return {
        ...w,
        balance: w.tokens[0].balance,
        currency: w.tokens[0].currency,
        currencyId: w.tokens[0].currencyId,
        holdBalance: w.tokens[0]?.holdBalance ?? undefined,
      };
    });

  const walletsByCurrency = selectedCurrency && [...wallets, ...walletsWithTokensOrder];

  const availableToWallets = selectedWallet && walletsByCurrency.filter(w => w._id !== selectedWallet);

  const handleDisableResend = () => {
    const timeout = setTimeout(() => {
      setDisableResend(false);
    }, 60000);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (merchantId) {
      if (selectedCurrencyId) {
        getMerchantWallets(merchantId, {
          typeNetwork: networkFilter,
          limit: -1,
          currencyIds: selectedCurrencyId,
          typeWallet: 'merchant',
          positiveBalance: false,
        });
      }
    }
  }, [merchantId, networkFilter, selectedCurrencyId]);

  useEffect(() => {
    if (currencyId) {
      setSelectedCurrencyId(currencyId);
      setSelectedCurrency(merchantBalances.find(merchantBalance => merchantBalance.currency.id === selectedCurrencyId));
    }
  }, [currencyId]);

  useEffect(() => {
    setSelectedCurrency(merchantBalances.find(merchantBalance => merchantBalance.currency.id === selectedCurrencyId));
  }, [selectedCurrencyId]);

  useEffect(() => {
    if (selectedWallet) {
      setSelectedWallet(selectedWallet);
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (sentToWallet && selectedWallet && selectedCurrency) {
      const findSentToWallet = availableToWallets.find(w => w.address === sentToWallet);
      setSelectedSentToWallet(findSentToWallet?._id);
    }
  }, [sentToWallet, selectedWallet, selectedCurrency]);

  const closeWithdrawModal = () => {
    handleCloseWithdraw();
  };

  useEffect(() => {
    if (sentToWallet || selectedSentToWallet) {
      setShowToRequired(false);
    }
  }, [sentToWallet, selectedSentToWallet]);

  useEffect(() => {
    if (selectedWallet) {
      const currentWallet = selectedWallet && walletsByCurrency?.find(wallet => wallet._id === selectedWallet);
      setIsSelectedWallet(currentWallet);
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (isSelectedWallet) {
      const currentCurrency = currencies.find(currency => isSelectedWallet.currencyId === currency._id);
      setDecimalsLimit(currentCurrency ? currentCurrency.decimals : 18);
    } else {
      const currentCurrency = currencies.find(currency => selectedCurrency?.currency?.id === currency._id);
      setDecimalsLimit(currentCurrency ? currentCurrency.decimals : 18);
    }

    return () => withdrawalEstimateMaxReset();
  }, [isSelectedWallet, selectedCurrency]);

  useEffect(() => {
    if (isSelectedWallet) {
      checkAutosignStatus(merchantId, isSelectedWallet._id);
    }
  }, [merchantId, isSelectedWallet]);

  useEffect(() => {
    setIsShowAutosignModal(autosignStatus);
  }, [autosignStatus]);

  const getWithdrawalInfo = async (merchantId, walletId) => {
    try {
      const response = await cryptoApi.getWithdrawalInfo(merchantId, walletId);
      const data = await response.data;
      setWithdrawalInfo(data);
    } catch (error) {
      console.log('error');
    }
  };

  const setFetchTimer = (action, value) => {
    ratesFetchTimer.current && clearTimeout(ratesFetchTimer.current);
    ratesFetchTimer.current = setTimeout(() => action(value), 500);
  };

  useEffect(() => {
    if (sentAmountBalance) {
      setFetchTimer(convertTokensToUsd, sentAmountBalance);
    } else {
      setGetAmountUSD('');
    }
  }, [sentAmountBalance, showDecimalsWarning]);

  useEffect(() => {
    if (sentAmountBalance && destinationWallet) {
      estimateFee(sentAmountBalance || 0);
    }
  }, [priority, sentAmountBalance, destinationWallet, isSelectedWallet]);

  const debouncedEstimateFee = useRef(
    debounce(async (...params) => {
      try {
        const response = await cryptoApi.getFeeEstimate(...params);
        const data = response?.data;
        if (data) {
          setFees(data);
          setShowWarningMsg(false);
          setShowWarningAddress(false);
          setShowWarningRecipient(false);
          setWarningMsg('');
          setWarningAddress('');
          setWarningRecipient('');
          setShowWarningMaxMsg(false);
          setWarningMaxMsg('');
        }

        setLoadingFees(false);
      } catch (error) {
        const errMsg = error?.data?.message;
        notification.error({
          message: errMsg
        })
        if (errMsg?.includes('have enough')) {
          setShowWarningMsg(true);
          setWarningMsg(errMsg);
          setShowWarningAddress(false);
          setWarningAddress('');
          setFees(null);
        }
        if (errMsg?.includes('recipient balance needs')) {
          setShowWarningMsg(false);
          setWarningMsg('');
          setShowWarningAddress(false);
          setWarningAddress('');
          setShowWarningRecipient(true);
          setWarningRecipient(errMsg);
          setFees(null);
        }
        if (errMsg === 'Address INVALID') {
          setFees(null);
          setShowWarningAddress(true);
          setShowWarningMsg(false);
          setWarningMsg('');
          setWarningAddress('Address is invalid');
        }
        setShowWarningMaxMsg(false);
        setWarningMaxMsg('');

        setLoadingFees(false);
      }
    }, 3000)
  );

  useEffect(() => {
    if (sign && sentAmountBalance && destinationWallet) {
      estimateFee(sentAmountBalance);
    }
  }, [sign])

  const estimateFee = value => {
    setLoadingFees(true);
    if (isSelectedWallet && value) {
      debouncedEstimateFee.current(
        merchantId,
        isSelectedWallet?._id,
        destinationWallet,
        `${value}`,
        isSelectedWallet?.hasOwnProperty('holdBalance') ? isSelectedWallet?.currencyId : undefined,
        isEstimatePriority ? priority : undefined,
        password ? password : undefined,
        sign ? sign : undefined
      );
    } else {
      setLoadingFees(false);
    }
  };

  const convertTokensToUsd = async value => {
    const rates = await getCurrenciesRate();

    if (rates && rates.data && isSelectedWallet) {
      const amountInUsd = value * +rates.data[isSelectedWallet?.currency];
      setGetAmountUSD(amountInUsd);
    }
    if (rates && rates.data && selectedCurrency) {
      const amountInUsd = value * +rates.data[selectedCurrency?.currency?.name];
      setGetAmountUSD(amountInUsd);
    }
  };

  const convertUsdToTokens = async value => {
    if (+value) {
      setGetAmountUSD(value);

      const rates = await getCurrenciesRate();

      if (rates && rates.data && isSelectedWallet) {
        const getCurrencyAmount = (value / +rates.data[isSelectedWallet?.currency]).toFixed(decimalsLimit) || 0;
        setSentAmountBalance(getCurrencyAmount);
        setShowDecimalsWarning(false);
        estimateFee(getCurrencyAmount);
      }
    } else {
      setSentAmountBalance('');
      setGetAmountUSD('');
    }
  };

  const handleEstimateMax = async () => {
    setFetchingMaxAmount(true);

    const data = {
      to: destinationWallet,
      currencyToken: isSelectedWallet?.hasOwnProperty('holdBalance') ? isSelectedWallet?.currencyId : undefined,
      priority: isEstimatePriority ? priority : undefined,
      password: password || undefined,
      sign: sign || undefined
    };

    if (!destinationWallet) {
      setShowToRequired(true);
    }

    if (merchantId && isSelectedWallet && (sentToWallet || selectedSentToWallet)) {
      try {
        const response = await cryptoApi.withdrawalEstimateMax(merchantId, isSelectedWallet._id, data);
        const dataAmount = await response.data;
        if (dataAmount) {
          setSentAmountBalance(dataAmount?.amount);
          setShowDecimalsWarning(false);
          await convertTokensToUsd(dataAmount?.amount);

          setShowWarningMaxMsg(false);
          setWarningMaxMsg('');
        }
      } catch (error) {
        const errMsg = error?.data?.message;

        if (errMsg) {
          setShowWarningMaxMsg(true);
          setWarningMaxMsg(errMsg);
          setShowWarningMsg(false);
          setWarningMsg('');
          setShowWarningAddress(false);
          setWarningAddress('');
        }
      }
    }

    setFetchingMaxAmount(false);
  };

  const sendWithdraw = () => {
    setDisableResend(true);

    handleDisableResend();
    if (isSelectedWallet && destinationWallet && (sentToWallet || selectedSentToWallet)) {
      makeWithdraw(
        isSelectedWallet?._id,
        destinationWallet,
        `${sentAmountBalance}`,
        merchantId,
        isSelectedWallet?.hasOwnProperty('holdBalance') ? isSelectedWallet?.currencyId : undefined,
        isEstimatePriority ? priority : undefined,
        comment,
        password || undefined,
        sign || undefined
      ).then(response => {
        if (response && response.data) {
          if (response.data.twoFactorToken || response.data.emailSent || response.data.codeSent) {
            setShowTwoFaModal(true);
          }
        }
      });
    }
  };

  const clearWithdrawState = () => {
    closeWithdrawModal();
  };

  const uploadAutosign = data => {
    const { password, sign } = data;
    setPassword(password || undefined);
    setSign(sign || undefined);

    setIsOpenAutosignModal(false)
  };

  const disabledRequest =
    !selectedCurrency ||
    !selectedWallet ||
    !sentAmountBalance ||
    !destinationWallet ||
    showWarningMsg ||
    showWarningAddress ||
    showWarningRecipient ||
    showWarningMaxMsg;

  const selectedCurrencyValue = selectedCurrency && (
    <CustomSelectOption
      name={selectedCurrency.currency.name}
      title={selectedCurrency.currency.title}
      balanceValue={selectedCurrency.balance.value}
      balanceUsd={selectedCurrency.balance.usd}
      identity="currency"
    />
  );

  const fullInfoAboutSelectedCurrency = selectedCurrency && merchantWallets?.find(item => item._id === selectedWallet);
  const selectedTokenTypeEnd =
    (fullInfoAboutSelectedCurrency &&
      tokenTypeEnd(
        fullInfoAboutSelectedCurrency.currencyType,
        fullInfoAboutSelectedCurrency.nodeType,
        fullInfoAboutSelectedCurrency.currency
      )) ||
    '';

  return (
    <>
      <WithdrawTwoFaConfirm
        isFromHome={true}
        isVisible={showTwoFaModal}
        setIsVisible={setShowTwoFaModal}
        action={makeWithdraw}
        fetching={fetching}
        clearWithdrawState={clearWithdrawState}
        body={[
          isSelectedWallet?._id,
          destinationWallet,
          `${sentAmountBalance}`,
          merchantId,
          isSelectedWallet?.hasOwnProperty('holdBalance') ? isSelectedWallet?.currencyId : undefined,
          isEstimatePriority ? priority : undefined,
          comment,
          password || undefined,
          sign || undefined,
        ]}
        withdrawResetTwoFa={withdrawResetTwoFa}
      />
      <Modal
        className="withdraw__modal"
        title={t('withdraw.withdraw')}
        width={446}
        visible={openWithdraw}
        footer={null}
        onCancel={closeWithdrawModal}
      >
        <div className="withdraw__modal-container">
          <div className="withdraw__modal-container__currency">
            <span>{t('currency')}</span>
            <Select
              value={selectedCurrency ? selectedCurrencyValue : t('wallets.chooseCurrency')}
              onChange={value => {
                setSelectedCurrencyId(value);
                setSelectedWallet(undefined);
                setSentAmountBalance('');
                setGetAmountUSD('0.00');
                setSendToLabelState('externalWallet');
                setSentToWallet(undefined);
                setSelectedSentToWallet(undefined);
                setIsSelectedWallet(undefined);
                setShowDecimalsWarning(false);
                setDestinationWallet(undefined);
                setShowWarningAddress(false);
                setShowWarningRecipient(false);
                setShowWarningMsg('');
                setWarningMsg('');
                setShowWarningMaxMsg(false);
                setWarningMaxMsg('');
                setWarningAddress('');
                setWarningRecipient('');

                setFees(null);
              }}
              showSearch
              className={`withdraw__modal-container__selected-${selectedCurrency ? 'active' : 'initial'}`}
              filterOption={(input, option) => (option?.title ?? '').toLowerCase().includes(input.toLowerCase())}
            >
              {merchantBalances?.map(currency =>
                currency.balance.usd !== 0 ? (
                  <Select.Option
                    key={currency.currency.id}
                    title={currency.currency.title}
                    value={currency.currency.id}
                  >
                    <CustomSelectOption
                      name={currency.currency.name}
                      title={currency.currency.title}
                      balanceValue={currency.balance.value}
                      balanceUsd={currency.balance.usd}
                      identity="currency"
                    />
                  </Select.Option>
                ) : null
              )}
            </Select>
            {selectedCurrency && selectedCurrency.balance.value == 0 && (
              <span className="withdraw__modal-container__currency-zero">
                Ooops, you select a currency with zero balance. Please, choose another one
              </span>
            )}
          </div>
          <div className="replenish__modal-container__address">
            <span>{t('wallets.walletAddress')}</span>
            <Select
              disabled={!selectedCurrency || selectedCurrency.balance.value == 0}
              value={selectedWallet ?? t('multisendWithdraw.chooseWalletAddress')}
              onChange={value => {
                setSelectedWallet(value);
                getWithdrawalInfo(merchantId, value);
                setShowDecimalsWarning(false);
                setShowWarningAddress(false);
                setShowWarningMsg('');
                setWarningMsg('');
                setWarningAddress('');
                setShowWarningMaxMsg(false);
                setWarningMaxMsg('');
                setFees(null);
              }}
              className={`withdraw__modal-container__selected-${selectedWallet ? 'active' : 'initial'}`}
            >
              {selectedCurrency &&
                walletsByCurrency.length > 0 &&
                walletsByCurrency
                  ?.filter(w => w.balance.value != 0 && w.balance.usd !== 0 && w?.address !== selectedSentToWallet)
                  ?.map(wallet => {
                    return (
                      <Select.Option className="withdraw__modal-container__select" value={wallet._id} key={wallet._id}>
                        <CustomSelectOption
                          name={wallet.currency}
                          title={truncate(wallet.address, 14)}
                          balanceValue={wallet.balance.value}
                          balanceUsd={wallet.balance.usd}
                          identity="wallet"
                          currencyType={wallet.currencyType}
                          nodeType={wallet.nodeType}
                        />
                      </Select.Option>
                    );
                  })}
            </Select>

            {selectedWallet && selectedCurrency?.currency?.type === 'token' && (
              <div className="replenish__modal-container__token">
                {fullInfoAboutSelectedCurrency?.currency} {selectedTokenTypeEnd}:{' '}
                {`${fullInfoAboutSelectedCurrency?.balance?.value} ${fullInfoAboutSelectedCurrency?.currency} ${selectedTokenTypeEnd} - $${fullInfoAboutSelectedCurrency?.balance?.usd}`}
              </div>
            )}
          </div>

          <div className="withdraw__modal-container__sent-address">
            <div className="withdraw__modal-container__sent-address-sentTo">
              <span>Sent to</span>
              <Radio.Group
                disabled={
                  !selectedCurrency ||
                  !selectedWallet ||
                  selectedCurrency.balance.value == 0 ||
                  availableToWallets?.length === 0
                }
                value={sendToLabelState}
                onChange={e => {
                  setSendToLabelState(e.target.value);
                  setSentToWallet(undefined);
                  setSelectedSentToWallet(undefined);
                  setShowWarningAddress(false);
                  setDestinationWallet(undefined);
                }}
              >
                <Radio value="externalWallet">{t('withdraw.externalWallet')}</Radio>
                <Radio value="internalWallet" disabled={availableToWallets?.length === 0}>
                  {t('withdraw.internalWallet')}
                </Radio>
              </Radio.Group>
            </div>
            {sendToLabelState === 'externalWallet' && (
              <Input
                onChange={e => {
                  setSelectedSentToWallet(undefined);
                  setSentToWallet(e.target.value);
                }}
                value={sentToWallet}
                className="withdraw__modal-container__input"
                placeholder={t('wallets.walletAddress')}
              />
            )}
            {sendToLabelState === 'internalWallet' && (
              <Select
                onChange={value => {
                  setSentToWallet(undefined);
                  setSelectedSentToWallet(value);
                  setShowWarningAddress(false);
                }}
                disabled={
                  !selectedCurrency ||
                  !selectedWallet ||
                  selectedCurrency.balance.value == 0 ||
                  availableToWallets?.length === 0
                }
                value={selectedSentToWallet ?? t('multisendWithdraw.chooseWalletAddress')}
                className={`withdraw__modal-container__selected-${selectedSentToWallet ? 'active' : 'initial'}`} //
              >
                {selectedCurrency &&
                  selectedWallet &&
                  availableToWallets?.length > 0 &&
                  availableToWallets?.map(wallet => {
                    return (
                      <Select.Option
                        className="withdraw__modal-container__select"
                        value={wallet.address}
                        key={wallet._id}
                      >
                        <CustomSelectOption
                          name={wallet.currency}
                          title={truncate(wallet.address, 14)}
                          balanceValue={wallet.balance.value}
                          balanceUsd={wallet.balance.usd}
                          identity="walletTo"
                          currencyType={wallet.currencyType}
                          nodeType={wallet.nodeType}
                        />
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
            {showToRequired && (
              <span className="withdraw__modal-container__currency-zero">Fill in the recipient's address first</span>
            )}
            {showWarningAddress && <span className="withdraw__modal-container__currency-zero">{warningAddress}</span>}
            {isSelectedWallet && !isShowAutosignModal && (
              <div className="withdraw__modal-container__autosign" onClick={() => setIsOpenAutosignModal(true)}>
                <img src={uploadFile} alt="upload" />
                <span>{!sign ? t('withdraw.uploadAutosign') : `${sign?.slice(0, 15)}...`}</span>
              </div>
            )}
          </div>

          {selectedCurrency?.currency?.nodeType === 'ton' && (
            <div className="withdraw__modal-container__amount">
              <span>Comment</span>
              <Input
                className="withdraw__modal-container__input"
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            </div>
          )}

          <div className="withdraw__modal-container__amount">
            <div className="withdraw__modal-container__amount-balance">
              <span>{t('amount')}</span>
              {withdrawalInfo?.minimumWithdrawalUsd && (
                <span className="withdraw__modal-container__amount-minimum">
                  Minimum ${withdrawalInfo?.minimumWithdrawalUsd}
                </span>
              )}
            </div>
            <Input
              value={sentAmountBalance}
              onChange={e => {
                if (e.target.value < 0) {
                  setSentAmountBalance(0);
                  return;
                }
                if (
                  !e.target.value.toString().split('.')[1] ||
                  e.target.value.toString().split('.')[1].length <= decimalsLimit
                ) {
                  setSentAmountBalance(e.target.value);
                  setFetchTimer(convertTokensToUsd, e.target.value);
                  setShowDecimalsWarning(false);
                } else {
                  setShowDecimalsWarning(true);
                  setSentAmountBalance(sentAmountBalance);
                  setFetchTimer(convertTokensToUsd, sentAmountBalance);
                }
              }}
              className="withdraw__modal-container__input"
              type="number"
              suffix={
                <p
                  className={`withdraw__modal-container__input-max withdraw__modal-container__input-max-${identity}`}
                  onClick={handleEstimateMax}
                >
                  {fetchingMaxAmount ? (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: '#2A92FF' }} spin />} />
                  ) : (
                    <span style={{ color: '#2A92FF' }}>MAX</span>
                  )}
                </p>
              }
            />
            {showWarningMaxMsg && selectedWallet && (
              <span className="withdraw__modal-container__amount-warning">{warningMaxMsg}</span>
            )}
            {showDecimalsWarning && (
              <span className="withdraw__modal-container__amount-warning">
                The maximum decimal value for {isSelectedWallet?.currency} is {decimalsLimit}.
              </span>
            )}
            {showWarningMsg && selectedWallet && (
              <span className="withdraw__modal-container__amount-warning">{warningMsg}</span>
            )}
            {showWarningRecipient && selectedWallet && (
              <span className="withdraw__modal-container__amount-warning">{warningRecipient}</span>
            )}
            {isEstimatePriority && (
              <div
                className={`withdraw__modal-container__amount__transaction-speed withdraw__modal-container__amount__transaction-speed-${identity}`}
              >
                <Radio.Group
                  // disabled={validWallets?.length <= 1}
                  value={priority}
                  onChange={e => {
                    setPriority(e.target.value);
                    // estimateFee(sentAmountBalance || 0)
                  }}
                >
                  <Radio value="low">{t('withdraw.low')}</Radio>
                  <Radio value="medium">{t('withdraw.medium')}</Radio>
                  <Radio value="high">{t('withdraw.high')}</Radio>
                </Radio.Group>
              </div>
            )}
          </div>

          <div className="withdraw__modal-container__usd">
            <div className="withdraw__modal-container__usd-balance">
              <span>Exchange rate in USD:</span>
              <img src={repeatIcon} alt="exchange" />
            </div>
            <Input
              value={(+getAmountUSD).toFixed(2)}
              onChange={e => {
                setGetAmountUSD(e.target.value);
                setFetchTimer(convertUsdToTokens, e.target.value);
              }}
              className="withdraw__modal-container__input"
              type="number"
              prefix="$"
            />
          </div>

          {withdrawalInfo?.systemFeeWithdrawal ? (
            <div className="withdraw__modal-container__fee">
              <p className="withdraw__modal-container__fee-systemFeeWithdrawal">
                {t('wallets.payment', { systemFeeWithdrawal: withdrawalInfo?.systemFeeWithdrawal })}
              </p>
            </div>
          ) : null}

          {withdrawalInfo?.systemFeeWithdrawal === 0 ? (
            <div className="withdraw__modal-container__fee">
              <p className="withdraw__modal-container__fee-systemFeeWithdrawal">
                {t('wallets.payment', { systemFeeWithdrawal: withdrawalInfo.systemFeeWithdrawal })}
              </p>
            </div>
          ) : null}

          {sentAmountBalance && (
            <div className="withdraw__modal-container__total">
              <img src={dashedLine} alt="dashed" />
              <span className="withdraw__modal-container__total-total">{t('total')}</span>
              <span className="withdraw__modal-container__total-info">{t('wallets.feeCalc')}:</span>
              <span className="withdraw__modal-container__total-calculate">
                {!loadingFees &&
                  `${sentAmountBalance} ${isSelectedWallet?.currency || selectedCurrency?.currency?.name || ''}${' '}`}
                {loadingFees ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                  <span>
                    {fees ? ` + ${fees.minerFee} ${fees.currencyMinerFee} + ${fees.systemFee} ${fees.currencyFee}` : ''}{' '}
                  </span>
                )}
              </span>
            </div>
          )}

          <Button
            className="withdraw__modal-container__submit"
            onClick={sendWithdraw}
            loading={fetching}
            disabled={disableResend || disabledRequest}
          >
            {disableResend ? (
              <>
                {' '}
                {t('submit')} in <Timer validUntil={validUntil} onExpire={() => setDisableResend(false)} />{' '}
              </>
            ) : (
              t('submit')
            )}
          </Button>
        </div>
        <UploadAutosignWrapper
          step={autosignStep}
          uploadAutosign={uploadAutosign}
          onCancel={() => setIsOpenAutosignModal(false)}
          isOpen={isOpenAutosignModal}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  merchantWallets: state.merchantWallets.data,
  fetching: state.withdraw.fetching,
  fetchingMaxAmount: state.withdraw.fetchingMaxAmount,
  maxAmount: state.withdraw.maxAmount,
  currencies: state.currencies.data,
  autosignStep: state.withdraw.autosignStep,
  autosignStatus: state.withdraw.autosignStatus,
});

const mapDispatchToProps = dispatch => ({
  getMerchantWallets: getMerchantWallets(dispatch),
  makeWithdraw: makeWithdraw(dispatch),
  withdrawalEstimateMaxReset: () => dispatch(withdrawalEstimateMaxReset()),
  withdrawResetTwoFa: () => dispatch(withdrawResetTwoFa()),
  uploadNewAutosign: uploadNewAutosign(dispatch),
  checkAutosignStatus: checkAutosignStatus(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawModal);
