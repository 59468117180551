import React, { useState } from 'react';
import { Row, Col, Radio } from "antd";
import Button from '../Button';
import '../../layout/Modal.scss';
import { useTranslation } from 'react-i18next';
import { identity } from '../../utils/getIdentity';

const WalletModalType = ({ setWalletCreateMode, handleCancel }) => {
  const { t } = useTranslation();
  const [walletValue, setWalletValue] = useState('new');

  return (
    <React.Fragment>
      <div className="modal__text">
        <span>{t('wallets.existOrNewWallet')}</span>
      </div>
      <Radio.Group value={walletValue} style={{ width: '100%' }} onChange={e => setWalletValue(e.target.value)}>
        <Radio value="new">New wallet</Radio>
        <Radio value="existing">Exist wallet</Radio>
      </Radio.Group>
      <Row className="modal__buttons-wrapper">
        <Col>
          <Button
            type="secondary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={() => setWalletCreateMode(walletValue)}
          >
            Create
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WalletModalType;
