import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Row, Col, Form, Input, Radio, Checkbox } from 'antd';
import Button from '../Button';
import TagsInput from 'react-tagsinput';
import { useTranslation } from 'react-i18next';
import { createMerchantWallet } from '../../redux/actions/merchantWallets';
import { notificationsErrorShow } from '../../redux/actions/notifications';
import CurrencyDropdown from '../CurrencyDropdown';
import '../../layout/Modal.scss';
import { useEffect } from 'react';
import { identity } from '../../utils/getIdentity';

const ADD_WALLET_BY_DATA_LABELS = {
  key: 'privateKey',
  mnemonic: 'mnemonicPhrase',
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const BTC_CODE = '5edf2767c9ca4d5a342bf8ac';

const initialValues = {
  remember: true,
  addWalletBy: ADD_WALLET_BY_DATA_LABELS.key,
  [ADD_WALLET_BY_DATA_LABELS.key]: '',
  [ADD_WALLET_BY_DATA_LABELS.mnemonic]: [],
};

const WalletAddExisting = ({
  isFetching,
  merchantId,
  networkFilter,
  typeWallet,
  createMerchantWallet,
  notificationsErrorShow,
  handleCancel,
  page,
  walletsFilters,
  isFromMerchantsWallets,
  isFromClientsWallets,
  merchantData,
  currencies,
}) => {
  const [walletCurrency, setWalletCurrency] = useState([]);
  const [walletPrivateKey, setWalletPrivateKey] = useState('');
  const [activeMerchantCurrencies, setActiveMerchantCurrencies] = useState([]);
  const [walletSetMain, setWalletSetMain] = useState(false);
  const [isOpenedDropdownCurrency, setIsOpenedDropdownCurrency] = useState(false);
  const [visible, setVisible] = useState(false);
  const [setupPassword, setSetupPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabledButtons, setDisabledButtons] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { pathname } = useLocation();

  const [addWalletByValue, setAddWalletByValue] = useState('privateKey');
  const [words, setWords] = useState([]);

  const defaultPasteSplit = data => {
    return data.split(' ').map(d => d.trim());
  };

  const isBTCCurrency = walletCurrency === BTC_CODE;

  const handleSubmit = () => {
    if (!walletCurrency) {
      notificationsErrorShow({ message: t('wallets.addWalletMsg.currency') });
      return;
    }

    if (!walletPrivateKey && addWalletByValue === ADD_WALLET_BY_DATA_LABELS.key) {
      notificationsErrorShow({ message: t('wallets.addWalletMsg.key') });
      return;
    }

    if ((!words.length || words.length < 12) && addWalletByValue === ADD_WALLET_BY_DATA_LABELS.mnemonic) {
      notificationsErrorShow({ message: t('wallets.addWalletMsg.mnemonic') });
      return;
    }

    createMerchantWallet(
      merchantId,
      typeWallet,
      {
        setMain: walletSetMain,
        privateKey: addWalletByValue === ADD_WALLET_BY_DATA_LABELS.key ? walletPrivateKey : words.join(' '),
        isMnemonic: addWalletByValue === ADD_WALLET_BY_DATA_LABELS.mnemonic ? true : false,
        typeWallet: typeWallet,
        currenciesIds: walletCurrency,
        password: password || undefined
      },
      page,
      walletsFilters,
      networkFilter,
      isFromMerchantsWallets
    ).then(() => handleCancel());
  };

  useEffect(() => {
  //  if (isFromClientsWallets) return setAddWalletByValue(ADD_WALLET_BY_DATA_LABELS.mnemonic);
    isBTCCurrency
      ? setAddWalletByValue(ADD_WALLET_BY_DATA_LABELS.key)
      : setAddWalletByValue(form.getFieldValue('addWalletBy'));
  }, [isBTCCurrency, walletCurrency]);

  // useEffect(() => {
  //   isFromClientsWallets && setAddWalletByValue(ADD_WALLET_BY_DATA_LABELS.mnemonic);
  // }, [isFromClientsWallets, isFromMerchantsWallets, pathname]);

  const mnemonicMerchants = isFromMerchantsWallets && addWalletByValue === ADD_WALLET_BY_DATA_LABELS.mnemonic;

  const excludeCurrencies = isFromClientsWallets || mnemonicMerchants || isFromMerchantsWallets;

  useEffect(() => {
    if (merchantId && merchantData) {
      const activeCurrencies =
        typeWallet === 'merchant' ? currencies.filter(currency => currency.currencyType !== 'token') : currencies;
      const activeCurrenciesIds = activeCurrencies.map(({ _id }) => _id);
      const merchantCurrencies = activeCurrenciesIds.filter(curr => merchantData.currencies.indexOf(curr) !== -1);
      const excludedMerchantCurrencies = excludeCurrencies
        ? merchantCurrencies.filter(curr => curr !== BTC_CODE)
        : merchantCurrencies;
      setActiveMerchantCurrencies(excludedMerchantCurrencies);
    }
  }, [merchantId, merchantData, typeWallet]);

  useEffect(() => {
    if (setupPassword) {
      if (password === confirmPassword && password !== '' && confirmPassword !== '') {
        setDisabledButtons(false)
      } else {
        setDisabledButtons(true)
      }
    } else {
      setDisabledButtons(false)
      setPassword('');
      setConfirmPassword('')
    }
  }, [password, confirmPassword, setupPassword]);

  return (
    <div className="component merchant-settings"
    onClick={() => {isOpenedDropdownCurrency && !visible ? setIsOpenedDropdownCurrency(false) : console.log('nothing to see')}}
    >
      <CurrencyDropdown
        setFunc={setWalletCurrency}
        multiple={true}
        allCurrencies={false}
        noTokens={typeWallet === 'merchant'}
        merchantId={merchantId}
        create={typeWallet === 'merchant'}
        excludeCurrencies={excludeCurrencies ? [BTC_CODE] : []}
        individualTokens={typeWallet !== 'merchant' ? true : false}
        isFiltersAvailable={true}
        activeMerchantCurrencies={activeMerchantCurrencies}
        isOpenedDropdownCurrency={isOpenedDropdownCurrency}
        setIsOpenedDropdownCurrency={setIsOpenedDropdownCurrency}
        visible={visible}
        setVisible={setVisible}
      />

      <Form {...layout} initialValues={initialValues} className="modal-form form-create-merchant" form={form}>
        {!isBTCCurrency ? (
          <Form.Item name="addWalletBy" className="modal-form__label">
            <Radio.Group onChange={({ target: { value } }) => setAddWalletByValue(value)}>
              <Radio value={ADD_WALLET_BY_DATA_LABELS.key}>{t('privateKey')}</Radio>
              <Radio value={ADD_WALLET_BY_DATA_LABELS.mnemonic}>{t('auth.mnemonicPhrase')}</Radio>
            </Radio.Group>
          </Form.Item>
        ) : null}

        {addWalletByValue === ADD_WALLET_BY_DATA_LABELS.key ? (
          <Form.Item label={t('privateKey')} className="modal-form__label">
            <Input
              type="text"
              placeholder={t('privateKey')}
              className="modal-form__input"
              onChange={e => setWalletPrivateKey(e.target.value)}
              name={ADD_WALLET_BY_DATA_LABELS.key}
            />
          </Form.Item>
        ) : (
          <>
            <p className="modal-form__label modal-form__description">{t('profile.writeDownPhrase')}</p>
            <Form.Item label={t('auth.mnemonicPhrase')} className="modal-form__label">
              <TagsInput
                className={`login-form__phrase login-form__phrase-${identity} add-wallet__modal`}
                addKeys={[9, 13, 32]}
                value={words}
                onChange={tags => setWords(tags)}
                maxTags="12"
                addOnPaste={true}
                pasteSplit={defaultPasteSplit}
                inputProps={{
                  placeholder: t('auth.addTag'),
                }}
              />
            </Form.Item>
          </>
        )}
        {typeWallet === 'merchant' && (
          <div>
            <div className="modal-form__label">
              <Checkbox onChange={e => setWalletSetMain(e.target.checked)}>{t('wallets.setAsDefault')}</Checkbox>
            </div>
            <div className="modal__set-default">
              <Checkbox onChange={e => setSetupPassword(e.target.checked)}>{t('wallets.setPassword')}</Checkbox>
            </div>
          </div>
        )}
      </Form>

      {setupPassword && (
        <div>
          <div className="modal__set-password">
            <p>{t('password')}</p>
            <Input
              placeholder={t('password')}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="modal__set-password">
            <p>{t('signPassword.confirmPassword')}</p>
            <Input
              placeholder={t('signPassword.confirmPassword')}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className="modal__set-password--warning">* Password must be longer than or equal to 7 characters</div>
        </div>
      )}

      <Row className="modal__buttons-wrapper modal__buttons-wrapper-new">
        <Col>
          <Button type="secondary" className="modal__button" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            className={`modal__button modal__button-create modal__button modal__button-create=${identity}`}
            onClick={handleSubmit}
            loading={isFetching}
            disabled={disabledButtons}
          >
            {t('create')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  page: state.merchantWallets.page,
  isFetching: state.merchantWallets.modalFetching,
  walletsFilters: formValueSelector(props.filtersFormName)(state, 'search', 'order', 'limit', 'currencyIds'),
  merchantData: state.merchantData.data,
  currencies: state.currencies.data,
});

const mapDispatchToProps = dispatch => ({
  createMerchantWallet: createMerchantWallet(dispatch),
  notificationsErrorShow: error => dispatch(notificationsErrorShow(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletAddExisting);
